import React, { useEffect, useState } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Checkbox,
    Alert
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useUser } from '../components/userContext';
import BooleanButtonGroup from '../components/buttonGroup';
import TransportDesignBox from '../components/TransportDesignBox';

const transportTypes = [
    { label: 'Terminfahrt', value: 'TERMINFAHRT' },
    { label: 'Entlassung', value: 'ENTLASSUNG' },
    { label: 'Einweisung', value: 'EINWEISUNG' },
    { label: 'Verlegung', value: 'VERLEGUNG' },
    // { label: 'Dialyse', value: 'DIALYSE_HINFART' },
    // { label: 'Dialyse Rückfahrt', value: 'DIALYSE_RUECKFART' },
];

const WhenStep = ({ formData, setFormData, isExpertMode }) => {
    const [dateError, setDateError] = useState(null);
    const [timeSettings, setTimeSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useUser();
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);

    // Laden der Zeiteinstellungen
    useEffect(() => {
        const loadSettings = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/time-slot-settings`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                });

                if (!response.ok) throw new Error('Fehler beim Laden der Zeiteinstellungen');

                const data = await response.json();
                if (data.settings) {
                    console.log('Zeiteinstellungen geladen:', data.settings);
                    setTimeSettings(data.settings);
                }
            } catch (err) {
                console.error('Fehler:', err);
                setError('Zeiteinstellungen konnten nicht geladen werden');
            } finally {
                setLoading(false);
            }
        };

        if (user?.api_key) {
            loadSettings();
        }
    }, [user]);

    useEffect(() => {
        // Führt validateTimeSlot nur aus, wenn timeSettings geladen sind
        if (timeSettings && timeSettings.settings) {
            //console.log('validateTimeSlot called with:', formData.departureDateTime, formData.transport_type);
            const validationError = validateTimeSlot(formData.departureDateTime, formData.transport_type);
            setDateError(validationError);
        }
        // eslint-disable-next-line
    }, [timeSettings]);


    const validateTimeSlot = (dateTime, transportType) => {
        //console.log('validateTimeSlot called with:', dateTime, transportType);
        if (!dateTime || !transportType || !timeSettings.settings) return true;

        const settings = timeSettings.settings[transportType];
        if (!settings) {
            console.warn(`No settings found for transportType: ${transportType}`);
            return true;
        }
        const selectedTime = dayjs(dateTime);
        const currentTime = dayjs();
        const timeStr = selectedTime.format('HH:mm');

        // Prüfen der erlaubten Zeitspanne
        if (settings.startTime && settings.endTime) {
            if (timeStr < settings.startTime || timeStr > settings.endTime) {
                return `Buchungen sind nur zwischen ${settings.startTime} und ${settings.endTime} Uhr möglich`;
            }
        }

        // Prüfen der Buchungslogik
        switch (settings.bookingLogic) {
            case 'sameDay':
                if (selectedTime.isBefore(currentTime, 'day')) {
                    return 'Bestellungen sind nur für heute oder zukünftige Tage möglich';
                }
                break;

            //Vortag ohne Einschränkungen
            case 'noRestriction':
                if (selectedTime.isSame(currentTime, 'day')) {
                    return 'Bestellungen sind erst ab morgen möglich';
                }
                break;

            //24 Stunden vorher
            case '24hoursBefore':
                if (selectedTime.isBefore(currentTime.add(24, 'hour'))) {
                    return 'Bestellungen müssen mindestens 24 Stunden im Voraus erfolgen';
                }
                break;

            //x Stunden vorher
            case 'dayBeforeUntil':
                if (settings.hoursBefore) {
                    const minTime = currentTime.add(Number(settings.hoursBefore), 'hour');
                    if (selectedTime.isBefore(minTime)) {
                        return `Bestellungen müssen mindestens ${settings.hoursBefore} Stunden im Voraus erfolgen`;
                    }
                }
                break;

            //Vortag bis
            case 'previousDayUntil':
                if (settings.previousDayTime) {
                    const deadline = dayjs(selectedTime).subtract(1, 'day')
                        .set('hour', Number(settings.previousDayTime.split(':')[0]))
                        .set('minute', Number(settings.previousDayTime.split(':')[1]));
                    if (currentTime.isAfter(deadline)) {
                        return `Bestellungen müssen bis ${settings.previousDayTime} Uhr am Vortag erfolgen`;
                    }
                }
                break;

            //Vortag (Werktag) bis
            case 'previousWorkDayUntil':
                if (settings.previousWorkDayTime) {
                    let previousWorkDay = selectedTime.subtract(1, 'day');
                    while (previousWorkDay.day() === 0 || previousWorkDay.day() === 6) {
                        previousWorkDay = previousWorkDay.subtract(1, 'day');
                    }
                    const deadline = dayjs(previousWorkDay)
                        .set('hour', Number(settings.previousWorkDayTime.split(':')[0]))
                        .set('minute', Number(settings.previousWorkDayTime.split(':')[1]));
                    if (currentTime.isAfter(deadline)) {
                        return `Bestellungen müssen bis ${settings.previousWorkDayTime} Uhr am vorherigen Werktag erfolgen`;
                    }
                }
                break;

            default:
                break;
        }

        // Validate Step 2
        //FIXME: diese Zeilen Code stehen ebenfalls in tranportFormPage.js in der Gesamt-Validierung
        let isValid = true;
        if (formData.transport_type === 'DIALYSEFAHRT') {
            // Validierung für DIALYSEFAHRT: entweder is_recurring = true oder ein gültiges Datum für Hin- oder Rückfahrt
            const isDepartureDateTimeValid = dayjs(formData.departureDateTime).isAfter(dayjs().add(1, 'hour'));
            const isDepartureDateTimeInFarFuture = dayjs(formData.departureDateTime).isAfter(dayjs().add(10, 'years'));
            const isReturnDepartureTimeValid = formData.returnDepartureTime
                ? dayjs(formData.returnDepartureTime).isAfter(dayjs().add(1, 'hour'))
                : true;

            console.log(formData.returnDepartureTime);
            if (formData.transport_type === 'DIALYSEFAHRT') {
                // Validierung für DIALYSEFAHRT: entweder is_recurring = true oder ein gültiges Datum für Hin- oder Rückfahrt
                isValid =
                    !!formData.transport_type &&
                    (formData.is_recurring || formData.is_recurring_return ||
                        (isDepartureDateTimeValid &&
                            !isDepartureDateTimeInFarFuture) ||
                        (formData.return_trip &&
                            isReturnDepartureTimeValid && formData.returnDepartureTime));
            } else {
                // Standardvalidierung für andere Transporttypen
                isValid =
                    !!formData.transport_type &&
                    !!formData.departureDateTime &&
                    isDepartureDateTimeValid &&
                    !isDepartureDateTimeInFarFuture;
            }
            if (!isValid) {
                setError('Bitte geben Sie ein gültiges Datum und eine Uhrzeit für die Abfahrt an.');
                return;
            }
        }
        return null;
    };

    const calculateTimeWindow = (dateTime, transportType) => {
        //console.log('calculateTimeWindow: dateTime:', dateTime, 'transportType:', transportType);
        if (!dateTime || !transportType || !timeSettings.settings) return true;

        const settings = timeSettings.settings[transportType];
        if (!settings) {
            console.warn(`calculateTimeWindow: No settings found for transportType: ${transportType}`);
            return true;
        }

        const selectedTime = dayjs(dateTime, 'YYYY-MM-DDTHH:mm:ss');
        if (!selectedTime.isValid()) {
            //console.error('calculateTimeWindow: Invalid dateTime format');
            return true;
        }
        //console.log('mintime:', settings.minusTime, 'plustime:', settings.plusTime);
        // Check if minusTime and plusTime are both undefined or null
        if (!settings.minusTime && !settings.plusTime) {
            console.error('Abbruch: MinusTime und PlusTime sind entweder null oder leer.');
            return false;
        }


        const windowStart = settings.minusTime
            ? selectedTime.subtract(Number(settings.minusTime.split(':')[0]), 'hour').subtract(Number(settings.minusTime.split(':')[1]), 'minute')
            : selectedTime;
        const windowEnd = settings.plusTime
            ? selectedTime.add(Number(settings.plusTime.split(':')[0]), 'hour').add(Number(settings.plusTime.split(':')[1]), 'minute')
            : selectedTime;


        //console.log('calculateTimeWindow: windowStart:', windowStart.format('HH:mm'), 'windowEnd:', windowEnd.format('HH:mm'));
        return {
            start: windowStart.format('HH:mm'),
            end: windowEnd.format('HH:mm')
        };
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        // Validierung bei Änderung
        if (name === 'departureDateTime' || name === 'transport_type') {
            const validationError = validateTimeSlot(
                name === 'departureDateTime' ? value : formData.departureDateTime,
                name === 'transport_type' ? value : formData.transport_type
            );
            setDateError(validationError);
        }
    };

    const getTimeRangeText = () => {

        if (!formData.departureDateTime || !formData.transport_type || !timeSettings.settings) {
            return null;
        }

        const settings = timeSettings.settings[formData.transport_type];
        if (!settings) {
            console.warn(`No settings found for transportType: ${formData.transport_type}`);
            return true;
        }

        const window = calculateTimeWindow(formData.departureDateTime, formData.transport_type);

        if (window) {
            return `Ihr Transport wird im Zeitraum ${window.start} - ${window.end} Uhr durchgeführt.`;
        }

        return null;
    };

    if (loading) return <Typography>Lade Einstellungen...</Typography>;
    if (error) return <Alert severity="error">{error}</Alert>;

    //const transportTypes = timeSettings?.settings ? Object.keys(timeSettings.settings) : Object.keys(timeSettings || {});

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6">Wann soll der Transport durchgeführt werden?</Typography>

            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Art des Transports</FormLabel>
                <RadioGroup
                    row
                    aria-label="transport_type"
                    name="transport_type"
                    value={formData.transport_type || ''}
                    onChange={handleChange}
                    disabled={isTransportApproved}
                >
                    {transportTypes.map((type) => (
                        <FormControlLabel
                            key={type.value}
                            value={type.value}
                            control={<Radio />}
                            label={type.label}
                            disabled={isTransportApproved}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <Box mt={isExpertMode ? 2 : 4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DateTimePicker
                        label={formData.transport_type === 'TERMINFAHRT' ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'}
                        name="departureDateTime"
                        value={formData.departureDateTime || null}
                        onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                        ampm={false}
                        format="DD.MM.YYYY HH:mm"
                        disabled={isTransportApproved}
                        slotProps={{
                            textField: {
                                error: !!dateError,
                                helperText: dateError,
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>

            {getTimeRangeText() && dateError === null && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    {getTimeRangeText()}
                </Typography>
            )}

            {formData.transport_type === 'TERMINFAHRT' && (
                <Box mt={2}>
                    {isExpertMode ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.ambulatory || false}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        ambulatory: e.target.checked
                                    }))}
                                    name="ambulatory"
                                />
                            }
                            label="Rückfahrt hinzufügen"
                        />
                    ) : (
                        <BooleanButtonGroup
                            fieldName="ambulatory"
                            label="Rückfahrt hinzufügen?"
                            options={[
                                { label: 'Ja', value: true },
                                { label: 'Nein', value: false },
                            ]}
                            formData={formData}
                            setFormData={setFormData}
                            customLabelStyle={{ width: '200px', marginRight: '2px' }}
                        />
                    )}
                </Box>
            )}
        </TransportDesignBox>
    );
};

export default WhenStep;