import React, { useState, useEffect } from 'react';
import {
    Tabs,
    Tab,
    Container,
    Typography,
    Box,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    FormControl,
    Grid,
    CircularProgress,
    Alert,
} from '@mui/material';
import { useUser } from '../components/userContext';
import { useNavigate } from 'react-router-dom';

const transportTypes = [
    { label: 'Terminfahrt', value: 'TERMINFAHRT' },
    { label: 'Entlassung', value: 'ENTLASSUNG' },
    { label: 'Einweisung', value: 'EINWEISUNG' },
    { label: 'Verlegung', value: 'VERLEGUNG' },
    { label: 'Dialyse Hinfahrt', value: 'DIALYSE_HINFART' },
    { label: 'Dialyse Rückfahrt', value: 'DIALYSE_RUECKFART' },
];

const TimeSlotManagement = () => {
    const { user } = useUser(); // Anbemeldeter User
    const [activeTab, setActiveTab] = useState(0); // Aktueller Tab
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState(
        transportTypes.reduce((acc, type) => {
            acc[type.value] = {
                startTime: '',
                endTime: '',
                bookingLogic: '',
                hoursBefore: '',
                minusTime: '',
                plusTime: '',
                previousDayTime: '', // Neues Feld für Vortag bis hh:mm Uhr
                previousWorkDayTime: '', // Neues Feld für Vortag (Werktags) bis hh:mm Uhr
            };
            return acc;
        }, {})
    );

    // Laden der Einstellungen beim Seitenaufruf
    useEffect(() => {
        const loadSettings = async () => {
            try {
                setLoading(true);
                setError(null);

                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/time-slot-settings`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.api_key,
                    },
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Fehler beim Laden der Einstellungen');
                }

                const data = await response.json();

                // Prüfen ob settings.settings existiert und Daten enthält
                if (data.settings && data.settings.settings) {
                    setFormData(prevData => {
                        // Für jeden Transporttyp die gespeicherten Einstellungen laden oder leere Werte beibehalten
                        return transportTypes.reduce((acc, type) => {
                            acc[type.value] = {
                                ...prevData[type.value], // Behalte die Default-Werte
                                ...data.settings.settings[type.value], // Überschreibe mit geladenen Werten
                            };
                            return acc;
                        }, {});
                    });
                }
            } catch (err) {
                console.error('Fehler beim Laden der Einstellungen:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (user && user.api_key) {
            loadSettings();
        }
    }, [user]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const transportType = transportTypes[activeTab].value;

        console.log(`Changing ${name} for ${transportType}:`, value); // Fügt Logging hinzu

        setFormData((prevData) => ({
            ...prevData,
            [transportType]: {
                ...prevData[transportType],
                [name]: value,
            },
        }));
    };

    const handleTimeChange = (name, value) => {
        const transportType = transportTypes[activeTab].value;

        // Speichere die Eingabe ohne sofortige Validierung
        setFormData((prevData) => ({
            ...prevData,
            [transportType]: {
                ...prevData[transportType],
                [name]: value,
            },
        }));
    };

    const validateTime = (name) => {
        const transportType = transportTypes[activeTab].value;
        const timePattern = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;

        const value = formData[transportType][name];

        // Überprüfe die Eingabe auf das korrekte Zeitformat
        if (value && !timePattern.test(value)) {
            console.log('Ungültige Uhrzeit:', value);

            // Setze das Feld auf leer, falls die Eingabe ungültig ist
            setFormData((prevData) => ({
                ...prevData,
                [transportType]: {
                    ...prevData[transportType],
                    [name]: '',
                },
            }));
        }
    };

    const validateHoursBefore = (name) => {
        const transportType = transportTypes[activeTab].value;
        const hourPattern = /^(?:[0-1]?[0-9]|2[0-3])$/; // Stundenformat 0-23

        const value = formData[transportType][name];

        // Überprüfe die Eingabe auf das korrekte Stundenformat
        if (value && !hourPattern.test(value)) {
            console.log('Ungültige Stunde:', value);

            // Setze das Feld auf leer, falls die Eingabe ungültig ist
            setFormData((prevData) => ({
                ...prevData,
                [transportType]: {
                    ...prevData[transportType],
                    [name]: '',
                },
            }));
        }
    };

    const handleSubmit = () => {
        const formattedData = {
            settings: Object.entries(formData).reduce((acc, [transportType, settings]) => {
                acc[transportType] = settings; // Transporttyp als Schlüssel hinzufügen
                return acc;
            }, {})
        };

        console.log('Daten für die Transportarten:', formattedData);

        saveSettings(formattedData); // Senden der korrekt formatierten Daten
    };

    const saveSettings = async (newSettings) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/time-slot-settings`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: user.api_key },
                body: JSON.stringify({ settings: newSettings }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Fehler beim Speichern:', errorData.error);
                throw new Error(`Speichern fehlgeschlagen: ${errorData.error}`);
            }
            const data = await response.json();
            console.log('Einstellungen erfolgreich gespeichert:', data);
            // Navigiere zur vorherigen Seite
            navigate(-1);
        } catch (error) {
            console.error('Fehler:', error.message);
            alert(`Fehler: ${error.message}`);
        }
    };

    const currentTransportType = transportTypes[activeTab].value;
    const currentData = formData[currentTransportType];

    return (
        <Container maxWidth="md">
            <Typography variant="h5" component="h1" textAlign="center" gutterBottom>
                Zeitkorridor Verwaltung
            </Typography>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                </Alert>
            ) : (
                <>
                    {/* Bestehender JSX-Code... */}


                    {/* Tabs */}
                    <Tabs value={activeTab} onChange={handleTabChange} centered>
                        {transportTypes.map((type) => (
                            <Tab key={type.value} label={type.label} />
                        ))}
                    </Tabs>

                    <Box component="form" mt={4} onSubmit={(e) => e.preventDefault()}>
                        <Typography variant="h6" gutterBottom>
                            Erlaubte Uhrzeit-Slots für {transportTypes[activeTab].label}en:
                        </Typography>

                        {/* Time Slot Inputs */}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="von"
                                    name="startTime"
                                    value={currentData.startTime || ''}
                                    onChange={(e) => handleTimeChange('startTime', e.target.value)}
                                    onBlur={() => validateTime('startTime')}
                                    fullWidth
                                    placeholder="hh:mm"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 5 }}
                                    error={!!currentData.startTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.startTime)}
                                    helperText={
                                        !!currentData.startTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.startTime)
                                            ? 'Bitte geben Sie eine gültige Uhrzeit ein (hh:mm)'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    label="bis"
                                    name="endTime"
                                    value={currentData.endTime || ''}
                                    onChange={(e) => handleTimeChange('endTime', e.target.value)}
                                    onBlur={() => validateTime('endTime')}
                                    fullWidth
                                    placeholder="hh:mm"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 5 }}
                                    error={!!currentData.endTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.endTime)}
                                    helperText={
                                        !!currentData.endTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.endTime)
                                            ? 'Bitte geben Sie eine gültige Uhrzeit ein (hh:mm)'
                                            : ''
                                    }
                                />
                            </Grid>
                        </Grid>

                        {/* Booking Logic */}
                        <Box mt={6}>
                            <Typography variant="h6" gutterBottom>
                                Erlaubte Logik für {transportTypes[activeTab].label}en:
                            </Typography>
                            <FormControl component="fieldset" fullWidth>
                                <RadioGroup
                                    name="bookingLogic"
                                    value={currentData.bookingLogic}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="sameDay" control={<Radio />} label="Selber Tag" />
                                    <FormControlLabel value="noRestriction" control={<Radio />} label="Vortag ohne Einschränkungen" />
                                    <FormControlLabel value="24hoursBefore" control={<Radio />} label="24h vorher" />
                                    <FormControlLabel
                                        value="dayBeforeUntil"
                                        control={<Radio />}
                                        label="x Stunden vorher"
                                    />
                                    {currentData.bookingLogic === "dayBeforeUntil" && (
                                        <Box mt={2}>
                                            <TextField
                                                type="text"
                                                name="hoursBefore"
                                                value={currentData.hoursBefore || ''}
                                                onChange={(e) => handleTimeChange('hoursBefore', e.target.value)}
                                                onBlur={() => validateHoursBefore('hoursBefore')}
                                                fullWidth
                                                label="Stunden vorher"
                                                placeholder="hh"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 2 }}
                                                error={!!currentData.hoursBefore && !/^(?:[0-1]?[0-9]|2[0-3])$/.test(currentData.hoursBefore)}
                                                helperText={
                                                    !!currentData.hoursBefore &&
                                                        !/^(?:[0-1]?[0-9]|2[0-3])$/.test(currentData.hoursBefore)
                                                        ? 'Bitte geben Sie eine gültige Stunde (00-23) ein'
                                                        : ''
                                                }
                                            />
                                        </Box>
                                    )}
                                    <FormControlLabel
                                        value="previousDayUntil"
                                        control={<Radio />}
                                        label="Vortag bis"
                                    />
                                    {currentData.bookingLogic === "previousDayUntil" && (
                                        <Box mt={2}>
                                            <TextField
                                                type="text"
                                                name="previousDayTime"
                                                value={currentData.previousDayTime || ''}
                                                onChange={(e) => handleTimeChange('previousDayTime', e.target.value)}
                                                onBlur={() => validateTime('previousDayTime')}
                                                fullWidth
                                                label="Uhrzeit (Vortag)"
                                                placeholder="hh:mm"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 5 }}
                                                error={!!currentData.previousDayTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.previousDayTime)}
                                                helperText={
                                                    !!currentData.previousDayTime &&
                                                        !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.previousDayTime)
                                                        ? 'Bitte geben Sie eine gültige Uhrzeit ein (hh:mm)'
                                                        : ''
                                                }
                                            />
                                        </Box>
                                    )}
                                    <FormControlLabel
                                        value="previousWorkDayUntil"
                                        control={<Radio />}
                                        label="Vortag (Werktags) bis"
                                    />
                                    {currentData.bookingLogic === "previousWorkDayUntil" && (
                                        <Box mt={2}>
                                            <TextField
                                                type="text"
                                                name="previousWorkDayTime"
                                                value={currentData.previousWorkDayTime || ''}
                                                onChange={(e) => handleTimeChange('previousWorkDayTime', e.target.value)}
                                                onBlur={() => validateTime('previousWorkDayTime')}
                                                fullWidth
                                                label="Uhrzeit (Werktags)"
                                                placeholder="hh:mm"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 5 }}
                                                error={!!currentData.previousWorkDayTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.previousWorkDayTime)}
                                                helperText={
                                                    !!currentData.previousWorkDayTime &&
                                                        !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.previousWorkDayTime)
                                                        ? 'Bitte geben Sie eine gültige Uhrzeit ein (hh:mm)'
                                                        : ''
                                                }
                                            />
                                        </Box>
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        {/* Corridor Settings */}
                        <Box mt={6}>
                            <Typography variant="h6" gutterBottom>
                                Zeitkorridor-Einstellungen für {transportTypes[activeTab].label}en:
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        label="MINUS"
                                        name="minusTime"
                                        value={currentData.minusTime || ''}
                                        onChange={(e) => handleTimeChange('minusTime', e.target.value)}
                                        onBlur={() => validateTime('minusTime')}
                                        fullWidth
                                        placeholder="hh:mm"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 5 }}
                                        error={!!currentData.minusTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.minusTime)}
                                        helperText={
                                            !!currentData.minusTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.minusTime)
                                                ? 'Bitte geben Sie eine gültige Uhrzeit ein (hh:mm)'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        label="PLUS"
                                        name="plusTime"
                                        value={currentData.plusTime || ''}
                                        onChange={(e) => handleTimeChange('plusTime', e.target.value)}
                                        onBlur={() => validateTime('plusTime')}
                                        fullWidth
                                        placeholder="hh:mm"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 5 }}
                                        error={!!currentData.plusTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.plusTime)}
                                        helperText={
                                            !!currentData.plusTime && !/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(currentData.plusTime)
                                                ? 'Bitte geben Sie eine gültige Uhrzeit ein (hh:mm)'
                                                : ''
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={4} display="flex" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Speichern
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
        </Container>
    );
};

export default TimeSlotManagement;
